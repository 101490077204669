<template>
  <van-field
      :label-width="labelWidth"
      :required="required"
      @click="show=true"
      readonly

      v-model="value"
      :label="inputTitle"
      :placeholder="placeholder"
  >
    <template #button>
      <slot name="button"></slot>
    </template>
  </van-field>

  <van-popup
      round
      v-model:show="show"
      position="bottom"
      :style="{ height: '300px' }"
  >
    <van-datetime-picker
        @confirm="yes"
        @cancel="no"
        :min-date="minDate"
        :max-date="maxDate"
        v-model="currentDate"
        :type="format"
        title="请选择"
    />
  </van-popup>
</template>

<script>
import {reactive} from 'vue'
import axios from "@/api/http.js";
import moment from 'moment'

export default {
  name: 'Time',
  props: {
    labelWidth:Number,
    required: {
      type: Boolean,
      default: false,
    },
    //配置默认时间
    val: {
      type: String,
      default() {
        return ''
      }
    },
    //配置时间格式返回时间格式
    format: {
      type: String,
      default() {
        return "date" //date time、year-month month-day datehour
      }
    },
    //配置输入框的标题
    inputTitle: {
      type: String,
      default() {
        return ''
      }
    },
    placeholder: {
      type: String,
      default() {
        return '请选择'
      }
    },
    minDate: {
      type: Object,
      default() {
        let time = new Date();
        return new Date('1970/01/01')
      }
    },
    maxDate: {
      type: Object,
      default() {
        let time = new Date();
        return new Date(moment().add(10, 'year').format('YYYY/MM/DD'))
      }
    }
  },
  data() {
    return {
      value: this.val,
      show: false,
      currentDate: new Date(),
      geShi: '',
    }
  },

  methods: {
    yes() {
      switch (this.format) {
          //date time、year-month month-day datehour
        case "date":
          this.geShi = "YYYY-MM-DD"
          break
        case "datetime":
          this.geShi = "YYYY-MM-DD HH:mm"
          break
        case "year-month":
          this.geShi = "YYYY-MM"
          break
        case "month-day":
          this.geShi = "MM-DD"
          break
        case "datehour":
          this.geShi = "YYYY-MM-DD HH"
          break


      }

      this.show = false
      this.value = moment(this.currentDate).format(this.geShi)
      this.$emit("dateEdit", this.value)
      this.$emit("update:val", this.value)
    },
    no() {
      this.show = false
    },
    set(val) {
      this.value = val
      this.$emit("update:value", this.value)
    }
  },
  watch: {
    val() {
      this.value = this.val
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.select-titme {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0000007d;
  //background-color: rgb(0,0,0,.1);
  top: 0;
  left: 0;
  z-index: 10;
}

.select-titme-rooter {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;

}
</style>
