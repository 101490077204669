import moment from 'moment'

export default {
    //上周
    shangZhou(format) {
        if (!format) {
            format = "YYYY-MM-DD"
        }
        let start = parseInt(moment().week(moment().week() - 1).startOf('week').valueOf() / 1000) + 86400
        let end = parseInt(moment().week(moment().week() - 1).endOf('week').valueOf() / 1000) + 86400
        return {
            start: moment(start * 1000).format(format),
            end: moment(end * 1000).format(format)
        }
    },
    //本周
    benZhou(format) {
        if (!format) {
            format = "YYYY-MM-DD"
        }
        let start = parseInt(moment().week(moment().week()).startOf('week').valueOf() / 1000);
        let end = parseInt(moment().week(moment().week()).endOf('week').valueOf() / 1000);
        return {
            start: moment(start * 1000).format(format),
            end: moment(end * 1000).format(format)
        }
    },
    //本月
    benYue(format) {
        if (!format) {
            format = "YYYY-MM-DD"
        }
        let start = parseInt(moment().month(moment().month()).startOf('month').valueOf() / 1000)
        let end = parseInt(moment().month(moment().month()).endOf('month').valueOf() / 1000)
        return {
            start: moment(start * 1000).format(format),
            end: moment(end * 1000).format(format)
        }
    },
    //上月
    shangYue(format) {
        if (!format) {
            format = "YYYY-MM-DD"
        }
        let start = parseInt(moment().month(moment().month() - 1).startOf('month').valueOf() / 1000)
        let end = parseInt(moment().month(moment().month() - 1).endOf('month').valueOf() / 1000)
        return {
            start: moment(start * 1000).format(format),
            end: moment(end * 1000).format(format)
        }
    },
    format(val, format = "YYYY-MM-DD") {

        if (!val) {
            return ''
        }
        val+=''
        if (val.split('-').length >1) {
            return val;
        }
        if (!val) {
            return ''
        }
        return moment(val * 1000).format(format);
    }


}