<template>
  <nav-header fixed title="编辑信息"/>
  <van-form @submit="onSubmit" v-if="JSON.stringify(userInfo) != '{}'">
    <div class="tou-xiang">
      <van-image class="bian-kuang" width="90" height="90" round :src="userInfo.avatar"/>
      <div>
        <uploader-file :bi-li="true" @lol-success="addImage">
          <a href="javascript:">修改头像</a>
        </uploader-file>
      </div>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            required
            v-model="userInfo.user_nickname"
            label="用户名"
        />
        <van-field
            required
            label="性别"
        >
          <template #input>
            <van-radio-group v-model="userInfo.sex" direction="horizontal">
              <van-radio :name="1">男</van-radio>
              <van-radio :name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            v-model="userInfo.address"
            label="常驻地址"
        />
      </van-cell-group>
    </div>
    <div class="padding-top-15">
      <van-cell-group inset>
        <van-field
            v-model="userInfo.position"
            label="职位"
        />
        <van-field
            v-model="userInfo.wx_number"
            label="微信号码"
        />
        <van-field
            v-model="userInfo.qq_number"
            label="QQ号码"
        />
        <Time input-title="出生日期" v-model:val="userInfo.birthday"/>
      </van-cell-group>
    </div>

    <div class="form-btn">
      <van-button type="primary" round block native-type="submit">
        保存
      </van-button>
    </div>

  </van-form>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import http from "../../api/http";
import moment from "moment";
import basis from "../../components/class/basis";
import AddImg from "../../components/file/AddImg";
import XEUtils from "xe-utils";
import UploaderFile from "../../components/file/uploaderFile";
import din_shi from "../../api/din_shi";
import Time from "../../components/time/Time";
import {updateUserInfo} from "../../api/cookie";

export default {
  name: 'UserInfo',
  components: {Time, UploaderFile, AddImg, NavHeader},
  mixins: [basis],
  data() {
    return {
      userInfo: {}
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    addImage({url}) {
      this.userInfo.avatar = url
    },
    getInfo() {
      http.get('/gzhphp/user/getUserInfo').then(da => {
        if (da.code === 1) {
          da.data.birthday = this.time(da.data.birthday)
          da.data.sex = da.data.sex?.id
          this.userInfo = da.data
        }
      })
    },
    onSubmit() {
      http.post('/gzhphp/user/editUserInfo', this.userInfo).then(da => {
        if (da.code === 1) {
          updateUserInfo().then(() => {
            din_shi.success(da.msg)
            this.$router.push('UserHome')
          })
        }
      })
    }
  },
  watch: {},

}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.tou-xiang {
  text-align: center;
  margin-top: 15px;
}

.edit-btn {
  margin: 10px;
  line-height: 30px;
}

.bian-kuang {
  border: 1px solid @gray-5;
}
</style>
