import {Options, Vue} from 'vue-class-component';
import Time from "@/api/Time";

export default {
    name: '',
    data() {
        return {
            deng_dai_time: 2000//等待时间
        }
    },
    methods: {
        echo(val, field = '', default_val = '') {

            if (field == '') {
                return val
            }
            const fieldList = field.split('.').map(n => {
                return n
            })

            try {
                fieldList.forEach(n => {
                    if (typeof val[n] != "undefined") {
                        val = val[n]
                    } else {
                        val = default_val
                    }
                })
            } catch (e) {
                console.log('echo 解析报错', val)
            }
            return val


        },
        /**
         * 是否显示转入数据
         * @param gysInfo
         */
        shiFoXianShiZhuanRuShuJu(shi_fo_hei_ming_dan, gong_si_fu_ze_ren_u_id) {
            if (shi_fo_hei_ming_dan > 0) {
                return true
            }
            if (typeof gong_si_fu_ze_ren_u_id == 'undefined' || gong_si_fu_ze_ren_u_id < 1) {
                return true
            }
            return false
        },
        //时间转换
        time(val, format = "YYYY-MM-DD") {
            return Time.format(val, format)
        },

        getId() {
            return Number(Math.random().toString().substr(3, 3) + Date.now()).toString(36);
        }
    }
}
