<template>
  <van-uploader :after-read="afterRead">
    <slot>
      <van-button size="mini" type="primary">上传文件</van-button>
    </slot>
  </van-uploader>

  <cai-jian @lol-success="caiJianOk" :bi-li="biLi" ref="caiJianDiv"/>
</template>

<script>
//上传按钮
export default {
  name: 'uploaderFile',
}
</script>
<script setup>
import * as qiniu from 'qiniu-js'
import CaiJian from "./caiJian";
import {defineEmit, ref, onMounted, defineProps} from 'vue'
import Img from "../../api/Img";
import http from "../../api/http";
import {b64HuanFile, getToken} from "../../api/file";
import XEUtils from "xe-utils";
import moment from "moment";

let dangQianWenJian = ref({})
const caiJianDiv = ref(null)
const emit = defineEmit(['lol-success'])
const props = defineProps({
  caiJian: Boolean, //true 开始裁剪
  biLi: { //裁剪比例
    type: Number,
    default() {
      return 1
    }
  }
})

function afterRead(file) {
  if (props.biLi) {
    dangQianWenJian.value = file
    caiJianDiv.value.show(file.content)
    //裁剪
  } else {
    Uploader(file.file)
  }
}

function caiJianOk(file) {
  file = b64HuanFile(file, dangQianWenJian.value.file.name)
  Uploader(file)
}

async function Uploader(file) {
  let config = await getToken(file)
  const fileType = Img.getFileType(file.name)
  const fileName = `${moment().unix()}${XEUtils.random(1000, 9999)}.${fileType}`
  const observable = qiniu.upload(file, fileName, config.data.token, {}, {})
  observable.subscribe({
    complete(res) {
      emit('lol-success', {url: `${config.data.host}/${res.key}`, name: file.name})
    }
  })

}


</script>

<style>

</style>
