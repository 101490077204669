<template>
  <div>
    <div :class="{'fixed':fixed,'show-select':showSelect}">
      <div :class="getClass">
        <van-nav-bar
            left-arrow
            :style="{'height':1}"
        >
          <template #left>
            <slot name="left">
              <a class="left-icon" href="" @click.prevent="onClickLeft">
                <van-icon name="arrow-left" size="18"/>
                {{ leftButton }}
              </a>
            </slot>
          </template>
          <template #title>
            <slot name="title">
              <template v-if="showSelect">
                <div class="select-div">
                  <div class="kuang">
                    <div class="right">
                      <van-form @submit="onSubmit">
                        <van-field
                            v-model="keyword"
                            :placeholder="placeholder"
                        />
                        <van-button native-type="submit" type="primary" size="small" class="sou-suo">
                          搜索
                        </van-button>
                      </van-form>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                {{ title }}
              </template>

            </slot>
          </template>
          <template #right>
            <slot name="right">
              <a href="" @click.prevent="onClickRight">
                {{ rightButton }}
              </a>
            </slot>
          </template>
        </van-nav-bar>

      </div>
    </div>
    <div class="zhan-wei" v-if="fixed"></div>
  </div>
</template>

<script>
// import {NavBar} from 'vant';
import XEUtils from "xe-utils";
import {onBeforeUnmount, onMounted, ref} from 'vue';
import $ from 'jquery'

export default {
  name: 'NavHeader',
  props: {
    placeholder: {
      type: String,
      default() {
        return '请输入关键词'
      }
    },
    showSelect: Boolean,//显示搜索框
    to: {
      type: [Object, String]
    },
    dongTaiColor: {
      type: Object,
    },
    pageName: String,
    class: String, //.nav-fff = 字体白色 无背景
    title: {
      type: String,
      default: ''
    },
    leftButton: {
      type: String,
      default: ''
    },
    rightButton: {
      type: String,
      default: ''
    },
    leftUrl: {
      type: String,
      default: ''
    },
    fixed: Boolean //true 使用浮动
  },

  data() {
    return {}
  },
  setup(props,{emit}) {
    const keyword = ref('') //搜索关键词
    let ding_shi = null
    onMounted(() => {
      if (typeof props.dongTaiColor === 'object') {
        window.xiao_hui_gun_dong = true
        window.addEventListener("scroll", jianTingGunDong, true);
      }
    })

    onBeforeUnmount(() => {
      window.xiao_hui_gun_dong = false
      console.log(window.removeEventListener("scroll", jianTingGunDong));
    })

    //坚挺滚动
    function jianTingGunDong() {
      if (window.xiao_hui_gun_dong !== true) {
        return
      }
      const offset = document.documentElement.scrollTop || document.body.scrollTop
      let touMing = Math.min(offset / props.dongTaiColor.top, 1)
      $('.van-nav-bar__content').css({
        'background-color': `rgb(${props.dongTaiColor.color},${touMing})`
      })

    }

    function onSubmit() {
      emit('yes',keyword.value)
    }

    return {
      keyword,
      onSubmit
    }
  },
  methods: {
    // 返回事件MessageList
    onClickLeft() {
      if (!XEUtils.isUndefined(this.to)) {
        this.$router.push(this.to)
        return;
      }
      if (XEUtils.isString(this.pageName)) {
        this.$router.push({name: this.pageName})
        return
      }
      if (this.leftUrl !== '') {
        this.$router.push({name: this.leftUrl})
        return;
      }

      //默认跳转到首页
      const url = location.href

      this.$router.back(-1)
      setTimeout(() => {
        if (url === location.href) {
          console.log('定时跳转')
          this.$router.push({name: 'Index'})
        }
      }, 500)

    },

  },
  computed: {
    getClass() {
      return this.class
    }
  }
}
</script>
<style scoped lang="less">
@import "../../assets/basis_css/style/var";
@import "../../assets/style";

@fixed-height: 46px;
.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  height: @fixed-height;
  z-index: 11;
}

.zhan-wei {
  height: @fixed-height;
}

.tu-biao {
  color: @gray-5;
}

.nav-fff {
  ::v-deep .van-badge__wrapper {
    color: @white;
  }

  ::v-deep .van-nav-bar__title {
    color: @white;
  }

  ::v-deep .van-nav-bar {
    background-color: transparent;
  }

  ::v-deep .van-hairline--bottom::after {
    border-width: 0;
  }

  ::v-deep .van-nav-bar__right {
    color: @white;
  }
}

.left-icon {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.show-select {
  :deep(.van-nav-bar__title) {
    flex: 1;
    max-width: 100%;
    padding-left: 50px;
    padding-right: 50px;


  }

  .select-div {
    width: 100%;
    background: #000;
    height: 100%;

    .kuang {
      display: flex;
      //padding: 1px 5px;
      background-color: @bai-se;

      .right {
        flex: 1;
        position: relative;

        ::v-deep .van-field {
          padding-top: 8px;
          padding-bottom: 5px;
          background-color: @hui-se;
          border-radius: @radius;
        }

        .sou-suo {
          position: absolute;
          top: 4px;
          right: 8px;
          border-radius: @radius;
          padding: 0 10px;
          font-size: 16px;
          height: 30px;
        }

        .select-type {
          position: absolute;
          left: 17px;
          top: 11px;
          z-index: 10;
          display: block;
          width: 70px;
          font-size: 14px;

          .select-type-icon {
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
    }
  }

}


</style>
